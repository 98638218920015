<template>
    <v-card rounded class="widget">
        <v-card-title flat>
            <v-card-title class="mb-0 py-0">Budget van voorziening</v-card-title>
            <v-spacer></v-spacer>
            <v-menu offset-y :close-on-content-click="false" max-width="290px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-btn v-on="on" icon>
                            <v-icon color="primary">mdi-filter-variant</v-icon>
                        </v-btn>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-text>
                        <!-- multiselect  -->
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    hide-details
                                    v-model="provisioning_id"
                                    :items="provisionings"
                                    item-text="name"
                                    item-value="id"
                                    label="Selecteer voorziening"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    hide-details
                                    :disabled="!provisioning_id"
                                    v-model="budget_id"
                                    :items="budgets"
                                    item-text="name"
                                    item-value="id"
                                    :label="budgetLabel"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-card-title>

        <v-card-text>
            <div class="text-center mb-3">
                <strong> {{ name }} </strong>
            </div>

            <v-simple-table dense class="mx-3">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Budget</th>
                            <th class="text-right">Bedrag</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Beschikbaar budget</td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(availableAmount) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Totaal bedrag betalingen</td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(totalPayment) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <!-- komt nieuwe omschrijving -->
                            <td>Ongebruikt budget</td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(unusedAmount) }}</strong>
                            </td>
                        </tr>
                    </tbody>
                    <v-divider vertical></v-divider>
                    <thead class="mt-5">
                        <tr>
                            <th class="text-left">Categorie</th>
                            <th class="text-right">Aantal</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Aantal deelnemers</td>
                            <td class="text-right">
                                <strong>{{ participantCount }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Deelnemers op wachtlijst</td>
                            <td class="text-right">
                                <strong>{{ waitingListCount }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Aantal betalingen</td>
                            <td class="text-right">
                                <strong>{{ paymentCount }}</strong>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            provisioning_id: null,
            budgets: [],
            selected_provisioning_id: null,
            provisionings: [],
            budget_id: null,
            totalPayment: 0,
            unusedAmount: 0,
            paymentCount: 0,
            participantCount: 0,
            waitingListCount: 0,
            availableAmount: 0,
            budgetManaged: false,
            name: this.$t("noBudgetSelected"),
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            if (!this.budget_id) return;
            this.$http
                .get(`${this.$config.backend_url}/dashboards/budgets/${this.budget_id}/summary`)
                .then((response) => {
                    const data = response.data;

                    // replace underscores with spaces
                    this.name = data.name.replace(/_/g, " ");
                    this.totalPayment = data.totalPayment;
                    this.unusedAmount = data.unusedAmount;
                    this.paymentCount = data.paymentCount;
                    this.participantCount = data.participantCount;
                    this.waitingListCount = data.waitingListCount;
                    this.availableAmount = data.availableAmount;
                    this.budgetManaged = data.budgetManaged;

                    this.chartData.labels = ["Ongebruikt", "Beschikbaar"];
                    this.chartData.datasets[0].data = [data.unusedAmount, data.availableAmount];

                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        returnAsCurrency(val) {
            let parsed = val.toFixed(2).replace(".", ",");
            parsed = parsed.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

            return `€ ${parsed}`;
        },
    },
    computed: {
        getFilterCount() {
            let count = 0;
            if (this.provisioning_id) count++;
            if (this.budget_id) count++;
            return count.toString();
        },
        budgetLabel() {
            return this.provisioning_id ? "Kies een budget" : "Kies eerst een voorziening";
        },
    },
    watch: {
        "$store.getters.productId": {
            handler() {
                this.getData();
            },
        },
        budget_id: {
            handler() {
                this.getData();
            },
        },
        provisioning_id: {
            handler(val) {
                let provisioning = this.provisionings.find((p) => p.id === val);
                if (provisioning) {
                    this.budgets = provisioning.budgets;
                }
            },
        },
        "$store.state.provisionings": {
            handler(newValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(this.provisionings)) {
                    this.provisionings = newValue;

                    // Sort provisionings
                    this.provisionings.sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    });
                }
            },
        },
    },
};
</script>

<style></style>
