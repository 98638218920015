<template>
  <v-app>
    <v-main class="mb-10">
      <Header />
      <v-container class="portal-subheader">
        <v-row>
          <v-col class="mb-2">
            <router-link v-if="!$vuetify.breakpoint.mdAndDown" to="/dashboard"
              class="d-flex align-center text-decoration-none mr-2 toolbar3-min-width">
              <h1 class="primary--text">{{ $config.name }}</h1>
            </router-link>
            <v-breadcrumbs class="ma-0 pa-0 mb-3" :items="items">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
                  <v-icon left>{{ item.icon }}</v-icon>
                  {{ $t(item.text).toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
              <template v-slot:divider>
                <v-icon>mdi-arrow-right</v-icon>
              </template>
            </v-breadcrumbs>
          </v-col>

          <v-col cols="7" v-if="$vuetify.breakpoint.mdAndUp" class="text-right">
            <div class="d-none d-md-block d-lg-block">
              <v-btn v-for="(item, index) in userMenuItems" :key="index" :to="item.link" text small color="primary"
                class="ml-1" rounded>
                <v-icon small left v-text="item.icon"></v-icon>
                {{ $t(item.title) }}
              </v-btn>
            </div>
          </v-col>

        </v-row>
        <router-view />
      </v-container>
    </v-main>

    <foot v-if="$vuetify.breakpoint.mdAndUp"></foot>
  </v-app>
</template>

<script>
import Header from "./Header";
import Foot from "./Footer.vue";
import menu from "../../menu.js";
// import UserModal from "../components/CreateCardholder.vue";

export default {
  name: "Home",
  components: {
    Header,
    Foot,
    // UserModal,
  },
  data() {
    return {
      menu,
      selected_card: null,
      card_ids: [],
      cards: [],
    };
  },
  created() {
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$http
        .get(this.$config.backend_url + "/user")
        .then((response) => {
          this.$store.dispatch("setUser", response.data);
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.error_response = error;
        });
    },
  },
  computed: {
    items() {
      let items = [
        {
          disabled: true,
          exact: true,
          link: true,
          text: this.$t("overview"),
          divider: "mdi-arrow-right",
          icon: "mdi-view-dashboard",
        },
      ];

      if (this.$route.name != "dashboard") {
        items = [
          {
            disabled: false,
            exact: false,
            link: true,
            text: this.$t("overview"),
            to: "/dashboard",
            divider: "mdi-arrow-right",
            icon: "mdi-view-dashboard",
          },
          {
            disabled: false,
            exact: false,
            link: false,
            text: this.$route.meta.title,
            to: this.$route.name,
            icon: this.$route.meta.icon,
          },
        ];
      }

      return items;
    },
    userMenuItems: function () {
      let menu_items = []
      menu_items = this.menu;

      if (this.$config.show_requests === false) {
        menu_items = menu_items.filter(function (m) {
          return m.title != "requests";
        });
      }

      return menu_items;
    },
  },
  watch: {
    selected_card: {
      handler() {
        this.$store.dispatch("setSelectedCard", this.selected_card);
      },
    },
    cards: {
      handler() {
        this.$store.dispatch("setCards", this.cards);
      },
    },
  },
};
</script>

<style lang="scss">
.card {
  width: 100%;
  border-radius: 30px;
  z-index: 50;
  position: relative;
  filter: drop-shadow(0 2px 4px #7f7f7f);
}

.card-panel {
  width: 100%;
  margin-top: -55px;
}

.background {
  background-color: #f5f5f5;
}

.theme--dark,
a {
  color: white !important;
}

.white-mode,
a {
  color: unset !important;
}
</style>
