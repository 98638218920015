import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: "dashboard_" + process.env.VUE_APP_INSTANCE_KEY,
    storage: window.localStorage,
});

let url = window.location.origin;

if (process.env.NODE_ENV == "development") {
    url = "http://localhost:8000";
} else {
    url;
}

const getDefaultState = () => {
    // Get the current date
    const currentDate = new Date();

    // Subtract 6 months from the current date
    currentDate.setMonth(currentDate.getMonth() - 6);

    // Set the date to the first day of the month
    currentDate.setDate(1);

    // Return the initial state
    return {
        contrast_mode: false,
        user: {},
        language: "nl",
        translations: {
            nl: {},
            en: {},
        },
        cards: [],
        selected_card: null,
        show_user_modal: false,
        show_qrcode_modal: false,
        participants: [],
        config_vars: {
            id: 1,
            name: "Dashboard",
            program_id: null,
            accent_color: "#333333",
            background_image_url: null,
            card_url: null,
            email: "",
            favicon_url: null,
            gtm: null,
            logo_url: null,
            phone_number: "000",
            pre_token: "606436489210",
            privacy_policy_url: "",
            return_url: "",
            secondary_color: "grey",
            shaped_widgets: true,
            primary_color: "#333333",
            groupit_program_id: null,
            linked_portal_instance_id: null,
            max_deposit_amount: null,
            allow_deposit: false,
        },
        partner_ids: [],
        provisioning_ids: [],
        budget_ids: [],
        product_id: null,
        product_ids: [],
        budget_id: null,
        provisioning_id: null,
        provisionings: [],
        products: [],
        start_date: currentDate.toISOString().slice(0, 10),
        end_date: new Date().toISOString().slice(0, 10),
    };
};

const resetState = () => {
    // Get the current date
    const currentDate = new Date();

    // Subtract 6 months from the current date
    currentDate.setMonth(currentDate.getMonth() - 6);

    // Set the date to the first day of the month
    currentDate.setDate(1);

    // Return the initial state
    return {
        selected_card: null,
        user: {},
        cards: [],
        participants: [],
        partner_ids: [],
        provisioning_ids: [],
        budget_ids: [],
        product_id: null,
        product_ids: [],
        budget_id: null,
        provisioning_id: null,
        provisionings: [],
        products: [],
        partners: [],
        availableTypes: [],
        start_date: currentDate.toISOString().slice(0, 10),
        end_date: new Date().toISOString().slice(0, 10),
    };
};
export default new Vuex.Store({
    state: getDefaultState,
    mutations: {
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, token) {
            state.status = "success";
            state.token = token;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            localStorage.removeItem("token");
            Object.assign(state, resetState());
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setContrastMode(state, payload) {
            state.contrast_mode = payload;
        },
        setCards(state, payload) {
            state.cards = payload;
        },
        setSelectedCard(state, payload) {
            state.selected_card = payload;
        },
        setShowUserModal(state, payload) {
            state.show_user_modal = payload;
        },
        setShowQrCodeModal(state, payload) {
            state.show_qrcode_modal = payload;
        },
        setSelectedCardBalance(state, payload) {
            state.selected_card.balance = payload;
        },
        setParticipants(state, payload) {
            state.participants = payload;
        },
        reset(state) {
            localStorage.removeItem("token");
            Object.assign(state, resetState());
        },
        setTranslations(state, payload) {
            state.translations = payload;
        },
        setConfigVars(state, payload) {
            state.config_vars = payload;
        },
        setStartDate(state, payload) {
            state.start_date = payload;
        },
        setEndDate(state, payload) {
            state.end_date = payload;
        },
        setPartnerIds(state, payload) {
            state.partner_ids = payload;
        },
        setProvisioningIds(state, payload) {
            state.provisioning_ids = payload;
        },
        setBudgetIds(state, payload) {
            state.budget_ids = payload;
        },
        setProductId(state, payload) {
            state.product_id = payload;
        },
        setProductIds(state, payload) {
            state.product_ids = payload;
        },
        setBudgetId(state, payload) {
            state.budget_id = payload;
        },
        setProvisioningId(state, payload) {
            state.provisioning_id = payload;
        },
        setProvisionings(state, provisionings) {
            state.provisionings = provisionings;
        },
        setProducts(state, products) {
            state.products = products;
        },
        setPartners(state, partners) {
            state.partners = partners;
        },
        setAvailableTypes(state, availableTypes) {
            state.available_types = availableTypes;
        },
    },
    actions: {
        logout({ commit }) {
            return new Promise((resolve) => {
                commit("logout");
                if (router.currentRoute.path != "/login") {
                    router.push("/login");
                }
                resolve();
            });
        },
        setUser({ commit }, data) {
            commit("setUser", data);
        },
        setContrastMode({ commit }, data) {
            commit("setContrastMode", data);
        },
        setCards({ commit }, data) {
            commit("setCards", data);
        },
        setSelectedCard({ commit }, data) {
            commit("setSelectedCard", data);
        },
        setShowUserModal({ commit }, data) {
            commit("setShowUserModal", data);
        },
        setShowQrCodeModal({ commit }, data) {
            commit("setShowQrCodeModal", data);
        },
        setSelectedCardBalance({ commit }, data) {
            commit("setSelectedCardBalance", data);
        },
        setParticipants({ commit }, data) {
            commit("setParticipants", data);
        },
        reset({ commit }, data) {
            commit("reset", data);
        },
        setTranslations({ commit }, data) {
            commit("setTranslations", data);
        },
        setConfigVars({ commit }, data) {
            commit("setConfigVars", data);
        },
        setStartDate({ commit }, data) {
            commit("setStartDate", data);
        },
        setEndDate({ commit }, data) {
            commit("setEndDate", data);
        },
        setPartnerIds({ commit }, data) {
            commit("setPartnerIds", data);
        },
        setProvisioningIds({ commit }, data) {
            commit("setProvisioningIds", data);
        },
        setBudgetIds({ commit }, data) {
            commit("setBudgetIds", data);
        },
        setProductId({ commit }, data) {
            commit("setProductId", data);
        },
        setProductIds({ commit }, data) {
            commit("setProductIds", data);
        },
        setBudgetId({ commit }, data) {
            commit("setBudgetId", data);
        },
        setProvisioningId({ commit }, data) {
            commit("setProvisioningId", data);
        },
    },
    getters: {
        isLoggedIn() {
            return !!localStorage.getItem("token");
        },
        token() {
            return localStorage.getItem("token");
        },
        authStatus: (state) => state.status,
        getUser: (state) => state.user,
        translate: (state) => (payload) => {
            if (state.translations.length != 0) {
                let result = state.translations.filter(
                    (translation) => translation.key == payload && translation.language == state.language
                );

                if (result.length != 0) {
                    return result[0].value;
                }

                return payload;
            } else {
                return payload;
            }
        },
        selectedCard: (state) => state.selected_card,
        cards: (state) => state.cards,
        contrastMode: (state) => state.contrast_mode,
        showUserModal: (state) => state.show_user_modal,
        showQrCodeModal: (state) => state.show_qrcode_modal,
        getParticipants: (state) => state.participants,
        getTranslations: (state) => state.translations,
        getConfigVars: (state) => state.config_vars,
        startDate: (state) => state.start_date,
        endDate: (state) => state.end_date,
        partnerIds: (state) => state.partner_ids,
        provisioningIds: (state) => state.provisioning_ids,
        budgetIds: (state) => state.budget_ids,
        productId: (state) => state.product_id,
        productIds: (state) => state.product_ids,
        budgetId: (state) => state.budget_id,
        provisioningId: (state) => state.provisioning_id,
        products: (state) => state.products,
        partners: (state) => state.partners,
        availableTypes: (state) => state.availableTypes,
    },
    plugins: [vuexLocal.plugin],
});
