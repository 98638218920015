<template>
  <v-row>
    <v-col cols="12">
      <v-card :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
          :class="$config.blurred_ui ? 'blurred-background' : null" :rounded="$config.shaped_widgets" class="pa-lg-5">
        <v-card-title>{{ $t("accountInfo") }}</v-card-title>
        <v-card-text>
          <v-form v-model="update_user_valid" ref="update_user">
            <v-alert
              v-if="update_user_success"
              outlined
              transition
              type="success"
              border="left"
              color="success"
            >
              {{ $t("saved") }}
            </v-alert>
            <v-alert
              v-if="update_user_error"
              outlined
              transition
              type="error"
              border="left"
              color="error"
            >
              {{ $t("error") }}
            </v-alert>
            <v-text-field
              :label="$t('firstName')"
              v-model="change_user.firstname"
              :rules="required"
              :disabled="update_user_loading"
            ></v-text-field>

            <v-text-field
              :label="$t('preposition')"
              v-model="change_user.prepostion"
              :disabled="update_user_loading"
            ></v-text-field>

            <v-text-field
              :label="$t('lastName')"
              v-model="change_user.lastname"
              :rules="required"
              :disabled="update_user_loading"
            ></v-text-field>

            <v-text-field
              :label="$t('emailAddress')"
              v-model="change_user.email"
              :rules="emailRules"
              :disabled="update_user_loading"
            ></v-text-field>

            <v-text-field
              :label="$t('username')"
              v-model="change_user.username"
              disabled
              aria-disabled
              :hint="$t('changeUsernameNotPossible')"
              persistent-hint
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-footer>
          <v-spacer></v-spacer>
          <v-btn
            text
            :loading="update_user_loading"
            color="primary"
            @click="updateUser()"
            >{{ $t("save") }}
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-footer>
      </v-card>
    </v-col>
    <v-col>
      <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
        <v-card-title>{{ $t("passwordChange") }}</v-card-title>
        <v-card-text class="pt-0 mt-0">
          <v-form ref="password_update" v-model="valid">
            <v-alert
              v-if="success"
              outlined
              transition
              type="success"
              border="left"
              color="success"
            >
              {{ $t("passwordUpdated") }}
            </v-alert>
            <v-alert
              v-if="error"
              outlined
              transition
              type="error"
              border="left"
              color="error"
            >
              {{ $t("passwordIncorrect") }}
            </v-alert>
            <password
              :label="$t('currentPassword')"
              v-model="reset_password.password"
              :strength_meter="true"
            ></password>

            <password
              :label="$t('newPassword')"
              v-model="reset_password.new_password"
            ></password>

            <v-text-field
              :label="$t('confirmPassword')"
              :type="show_password ? 'text' : 'password'"
              v-model="reset_password.new_password_confirmation"
              :rules="confirmPasswordRules"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-footer>
          <v-btn text @click="$refs.password_update.reset()">
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("reset") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            :loading="loading"
            @click="resetPassword()"
            color="primary"
            >{{ $t("save") }}
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-footer>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Password from '../components/Password.vue';
export default {
  components: {Password},
  name: "Profile",
  data() {
    return {
      loading: false,
      update_user_valid: false,
      valid: false,
      show_password: false,
      reset_password: {
        password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      change_user: {
        email: "",
        firstname: "",
        lastname: "",
        prepostion: "",
        username: "",
      },
      emailRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) ||
          this.$t("validEmail"),
      ],
      required: [(v) => !!v || this.$t("mandatory")],
      passwordRules: [
        (value) => !!value || this.$t("mandatory"),
        (value) =>
          (value &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/.test(
              value
            )) ||
          this.$t("passwordRules"),
      ],
      confirmPasswordRules: [
        (value) => !!value || this.$t("confirmPassword"),
        (value) =>
          value === this.reset_password.new_password || this.$t("noMatch"),
      ],
      success: false,
      error: false,
      update_user_success: false,
      update_user_error: false,
      update_user_loading: false,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.update_user_loading = true;
      this.$http
        .get(this.$config.backend_url + "/user")
        .then((response) => {
          this.$store.dispatch("setUser", response.data);
          let user = this.$store.getters.getUser;
          this.change_user.firstname = user.first_name;
          this.change_user.lastname = user.last_name;
          this.change_user.email = user.email;
          this.change_user.username = user.username;
          this.update_user_loading = false;
        })
        .catch((error) => {
          this.update_user_loading = false;
          this.error = true;
          this.error_response = error;
        });
    },
    updateUser() {
      this.update_user_loading = true;
      if (this.$refs.update_user.validate())
        this.$http
          .post(this.$config.backend_url + "/user/update", this.change_user)
          .then(() => {
            this.update_user_loading = false;
            this.update_user_success = true;
            this.update_user_error = false;
            this.getUser();
          })
          .catch(() => {
            this.update_user_loading = false;
            this.update_user_error = true;
            this.update_user_success = false;
          });
    },
    resetPassword() {
      this.success = false;
      this.error = false;

      if (this.$refs.password_update.validate())
        this.$http
          .post(
            this.$config.backend_url + "/user/change-password",
            this.reset_password
          )
          .then(() => {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.$refs.password_update.reset();
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
            this.success = false;
          });
    },
  },
  computed: {
    showUsername() {
      if (this.change_user.username == this.change_user.email) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss"></style>
