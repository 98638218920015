<template>
    <v-row>
        <v-col>
            <div v-if="initializing" class="initialize" style="
                    background-color: #333;
                    padding: 20px;
                    text-align: center;
                    border-radius: 5px;
                ">
                <p style="color: #fff; font-size: 18px">Een moment geduld...</p>
                <v-progress-circular large indeterminate color="#fff" class="mx-auto"></v-progress-circular>
            </div>

            <div v-else-if="faulty_instance" class="initialize"
                style="background-color: red; padding: 20px; text-align: center; border-radius: 5px">
                <p style="color: #fff; font-size: 18px">Configuratie niet correct</p>
                <!-- icon -->
                <v-icon large color="#fff">mdi-alert-circle</v-icon>
            </div>

            <v-card v-else :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
                :class="$config.blurred_ui ? 'blurred-background' : null" :rounded="$config.shaped_widgets"
                class="pa-lg-5 mx-auto" flat max-width="600">
                <v-card-title class="mb-2">{{ $t("login") }} <br />
                    {{ $config.app_name }}</v-card-title>
                <v-card-text class="pb-0 pt-0">
                    <!-- ACCOUNT ACTIVATED -->
                    <v-alert v-if="account_activated" outlined transition type="success" prominent border="left"
                        color="success">
                        {{ $t("yourAccountHasBeenActivated") }}
                    </v-alert>

                    <!-- TOKEN INVALID -->
                    <v-alert v-if="error_response == 'incorrect_token'" outlined transition type="warning" prominent
                        border="left" color="warning">
                        {{ $t("incorrectToken") }}

                        <p class="pt-2">
                            <a @click="$router.push('/verify-account')">
                                <strong>{{ $t("requestActivationLink") }}</strong>
                            </a>
                        </p>
                    </v-alert>

                    <!-- NOT VERIFIED -->
                    <v-alert v-if="error_response == 'not_verified'" outlined transition type="warning" prominent
                        border="left" color="warning">
                        {{ $t("userNotVerified") }}

                        <p class="pt-2">
                            <a @click="$router.push('/verify-account')">
                                <strong>{{ $t("requestActivationLink") }}</strong>
                            </a>
                        </p>
                    </v-alert>

                    <!-- NOT AUTHORIZED -->
                    <v-alert v-if="error_response == 'not_authorized'" outlined transition type="error" prominent
                        border="left" color="error">
                        {{ $t("loginIncorrect") }}
                    </v-alert>

                    <v-form ref="login_form" v-model="valid">
                        <v-text-field :label="$t('username')" filled v-model="form.name" :rules="nameRules"
                            @keyup.enter="login()" :hint="$t('usernameIsCaseSensitive')"></v-text-field>
                        <v-text-field :label="$t('password')" filled v-model="form.password" @keyup.enter="login()"
                            :type="show_password ? 'text' : 'password'"
                            :append-icon="!show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show_password = !show_password"></v-text-field>
                    </v-form>

                    <v-btn :rounded="$config.shaped_widgets" :loading="loading" :disabled="loading" @click="login()" block
                        class="primary" x-large>{{ $t("login") }}</v-btn>

                    <template v-if="$config.social_auth">
                        <v-divider></v-divider>

                        <v-btn dark class="my-5" block color="#DB4437">
                            <v-icon left>mdi-google</v-icon>
                            Inloggen met Google
                        </v-btn>

                        <v-btn dark class="my-5" block color="#3b5998">
                            <v-icon left>mdi-facebook</v-icon>
                            Inloggen met Facebook
                        </v-btn>
                    </template>
                </v-card-text>

                <div class="footer-panel mt-5">
                    <strong>{{ $t("loginProblems") }}</strong>

                    <v-list tabindex="3" flat color="transparent" dense>
                        <v-list-item-group color="primary">
                            <v-list-item :to="item.to" v-for="(item, i) in items" :key="i">
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            show_password: false,
            selected_item: "",
            items: [
                {
                    text: "forgotUsername",
                    icon: "mdi-arrow-right",
                    to: "forgot-username",
                },
                {
                    text: "forgotPassword",
                    icon: "mdi-arrow-right",
                    to: "password-reset",
                },
            ],
            valid: false,
            form: {
                name: "",
                password: "",
                c_password: "",
            },
            error_response: null,
            incorrect_token: false,
            error: false,
            loading: false,
            isFormValid: false,
            isRegisterForm: false,
            account_activated: false,
            counter: 0,
            initializing: true,
            nameRules: [
                (v) => !!v || `${this.$t("isRequired")}`,
                (value) => (value && /^\S*$/.test(value)) || this.$t("noSpacesAllowed"),
            ],
            passwordRules: [
                (value) => !!value || this.$t("mandatory"),
                (value) =>
                    (value &&
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/.test(
                            value
                        )) ||
                    this.$t("passwordRules"),
            ],
            c_passwordRules: [
                (v) =>
                    !this.isRegisterForm ||
                    !!v ||
                    `${this.$t("password")} ${this.$t("isRequired")}`,
                (v) => v == this.form.password || this.$t("passwordConfirm"),
            ],
            faulty_instance: false,
        };
    },
    created() {
        this.initialize();
    },
    mounted() {
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    },
    beforeDestroy() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    },
    methods: {
        handleVisibilityChange() {
            if (!document.hidden) {
                this.initialize();
            }
        },
        async initialize() {
            this.$store.commit("reset");
            this.faulty_instance = false;
            this.initializing = true;
            this.reloadCheck();
            await this.getTranslations();
            await this.getInstanceSettings();
        },
        reloadCheck() {
            let last_visit = localStorage.getItem("last_visit");
            // if last_visit is not set, set it to current time
            if (!last_visit) {
                localStorage.setItem("last_visit", new Date().getTime());
            }

            // if last visit is longer than 1 day, reload the page
            if (new Date().getTime() - last_visit > 86400000) {
                localStorage.setItem("last_visit", new Date().getTime());
                window.location.reload();
            }
        },
        async getTranslations() {
            let url = process.env.VUE_APP_BACKEND_URL + "/translations";
            await this.$http
                .get(url, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.$store.dispatch("setTranslations", response.data);
                        this.$i18n.setLocaleMessage("nl", response.data.nl);
                        this.$i18n.setLocaleMessage("en", response.data.en);
                    }
                })
                .catch(() => {
                    this.initializing = false;
                    this.faulty_instance = false;
                });

            return;
        },
        async getInstanceSettings() {
            await this.$http
                .get(process.env.VUE_APP_BACKEND_URL + "/instance-settings", {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then(async (response) => {
                    this.$store.dispatch("setConfigVars", response.data);
                    if (response.data.updated_at != this.$store.getters.getConfigVars.updated_at) {
                        window.location.reload();
                    }

                    this.initializing = false;
                    this.faulty_instance = false;
                })
                .catch(() => {
                    this.initializing = false;
                    // this.faulty_instance = true;
                });

            return;
        },
        login() {
            this.error_response = null;
            this.$refs.login_form.validate();

            if (this.valid && this.form.name && this.form.password) {
                this.loading = true;

                this.$http
                    .post(
                        this.$config.backend_url + "/login",
                        {
                            username: this.form.name,
                            password: this.form.password,
                        },
                        {
                            headers: {
                                "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                            },
                        }
                    )
                    .then((response) => {
                        this.loading = false;
                        localStorage.setItem("token", response.data);
                        this.$router.replace(this.$route.query.redirect || "dashboard");
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error_response = "not_authorized";
                        if (
                            error.response.data.error &&
                            error.response.data.error == "not_verified"
                        ) {
                            this.error_response = "not_verified";
                        }
                    });
            }
        },
        activateAccount() {
            this.account_activated = false;
            this.error_response = null;

            this.$http
                .post(this.$config.backend_url + "/activate-account", {
                    token: this.$route.query.token,
                })
                .then(() => {
                    this.account_activated = true;
                })
                .catch((error) => {
                    this.account_activated = false;
                    if (
                        error.response.data.status &&
                        error.response.data.status == "INCORRECT_TOKEN"
                    ) {
                        this.error_response = "incorrect_token";
                    } else {
                        this.incorrect_token = false;
                    }
                });
        },
    },
};
</script>

<style lang="scss">
.initialize {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
