import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/dashboard",
            name: "dashboard",
            component: require("@/views/Dashboard").default,
            meta: {
                middleware: true,
                title: "Overzicht",
                icon: "mdi-view-dashboard",
            },
        },
        {
            path: "/profile",
            name: "profile",
            component: require("@/views/Profile").default,
            meta: {
                middleware: true,
                title: "profile",
                icon: "mdi-account",
            },
        },
        {
            path: "/caregivers",
            name: "caregivers",
            component: require("@/views/participants/Index").default,
            meta: {
                middleware: true,
                title: "caregivers",
                icon: "mdi-account-multiple",
            },
        },
        // NON AUTHENTICATED ROUTES
        {
            path: "/offline",
            name: "offline",
            component: require("@/views/Offline").default,
        },
        {
            path: "/login",
            name: "login",
            component: require("@/views/Login").default,
        },
        {
            path: "/password-reset",
            name: "password-reset",
            component: require("@/views/RequestPasswordReset").default,
        },
        {
            path: "/forgot-username",
            name: "forgot-username",
            component: require("@/views/ForgotUsername").default,
        },
        {
            path: "/change-password",
            name: "change-password",
            component: require("@/views/ChangePassword").default,
        },
        {
            path: "*",
            redirect: "/dashboard",
        },
    ],
    mode: "history",
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        if (localStorage.getItem("token") !== null) {
            return next();
        } else {
            return next("/login");
        }
    } else {
        return next();
    }
});

export default router;
