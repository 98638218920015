<template>
    <v-card rounded class="widget">
        <v-card-title flat>
            <v-card-title class="mb-0 py-0"> {{ getTitle }} &euro; </v-card-title>
            <v-spacer></v-spacer>
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" v-on="on" icon>
                        <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                </template>

                <v-card style="max-width: 300px">
                    <v-card-text>
                        <!-- multiselect  -->
                        <v-row>
                            <v-col cols="12">
                                <v-menu
                                    ref="menu_s"
                                    v-model="menu_s"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            hide-details
                                            v-model="formattedStartDate"
                                            label="Start datum"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            outlined
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="start_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu_s = false">Annuleren</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu_s.save(start_date)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12">
                                <v-menu
                                    ref="menu_e"
                                    v-model="menu_e"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            hide-details
                                            v-model="formattedEndDate"
                                            label="Eind datum"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            outlined
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="end_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu_e = false">Annuleren</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu_e.save(end_date)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-select
                                    hide-details
                                    v-model="partner_ids"
                                    :items="partners"
                                    item-text="name"
                                    item-value="id"
                                    label="Selecteer partners"
                                    multiple
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    hide-details
                                    v-model="type"
                                    :items="transactionTypes"
                                    item-text="text"
                                    item-value="value"
                                    label="Selecteer type"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>

                            <v-col>
                                <v-select
                                    hide-details
                                    v-model="aggregate"
                                    :items="aggregates"
                                    item-text="text"
                                    item-value="value"
                                    label="Selecteer aggregatie"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-card-title>

        <v-card-text>
            <div class="text-left mb-3">
                Datum selectie: {{ date_range }} <br />
                Getoond per: {{ aggregateText }}
            </div>
            <bar-chart v-if="!loading" style="height: 250px" :chart-options="chartOptions" :chart-data="chartData" />

            <Loader v-else />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <transactions-modal></transactions-modal>
        </v-card-actions>
    </v-card>
</template>
<script>
import TransactionsModal from "../modals/Transactions.vue";
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    components: {
        BarChart: Bar,
        TransactionsModal,
    },
    data() {
        return {
            loading: true,
            allData: [],
            menu_e: false,
            menu_s: false,
            partners: [],
            partner_ids: [],
            type: "PAYMENT",
            // day, month or year
            aggregate: "month",
            aggregates: [
                { text: "Maand", value: "month" },
                { text: "Jaar", value: "year" },
            ],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                        color: "white",
                        align: "middle",
                        formatter: function (val) {
                            let parsed = val.toFixed(2).replace(".", ",");
                            parsed = parsed.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

                            return `€ ${parsed}`;
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";

                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat("nl-NL", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(context.parsed.y);
                                }
                                return label;
                            },
                        },
                    },
                },
            },
            chartData: {
                type: "bar",
                labels: [],
                datasets: [
                    {
                        label: "Totalen",
                        data: [],
                        backgroundColor: ["#FF6384"],
                        hoverBackgroundColor: ["#FF6384"],
                    },
                ],
            },
        };
    },
    created() {
        this.getDailyTotals();
        this.getPartners();
    },
    methods: {
        getDailyTotals() {
            this.loading = true;
            this.$http
                .get(
                    `${this.$config.backend_url}/dashboards/daily-totals?start_date=${this.$store.getters.startDate}&end_date=${this.$store.getters.endDate}&partner_ids=${this.partner_ids}&aggregate=${this.aggregate}&types=${this.$store.state.available_types}`
                )
                .then((response) => {
                    // if aggregate is day, we get an array of objects
                    if (this.aggregate === "day") {
                        let rows = response.data;
                        this.allData = rows;
                    } else {
                        // if aggregate is month or year, we get an object
                        let rows = this.transformData(response.data);
                        this.allData = rows;
                    }
                    this.loading = false;

                    this.filterByType();
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        transformData(data) {
            if (this.aggregate === "day") {
                return data.map((item) => {
                    return {
                        name: item.day,
                        amount: item.amount,
                    };
                });
            } else if (this.aggregate === "month") {
                return Object.keys(data).map((key) => {
                    return {
                        name: `${data[key].month_name} ${data[key].year}`,
                        amount: data[key].amount,
                        type: data[key].type,
                    };
                });
            } else if (this.aggregate === "year") {
                return Object.keys(data).map((key) => {
                    return {
                        name: data[key].year.toString(),
                        amount: data[key].amount,
                        type: data[key].type,
                    };
                });
            }
        },
        filterByType() {
            const filteredRows = this.allData.filter((row) => row.type === this.type);
            this.chartData.labels = filteredRows.map((row) => row.name);
            this.chartData.datasets[0].data = filteredRows.map((row) => row.amount);
        },
        getPartners() {
            this.loading = true;
            var product_ids = this.$store.getters.productIds;

            for (let product_id of product_ids) {
                this.$http
                    .get(this.$config.backend_url + "/dashboards/product-info/" + product_id)
                    .then((response) => {
                        this.partners = this.partners.concat(response.data.partners);
                        this.partners = this.partners.filter(
                            (partner, index, self) => index === self.findIndex((t) => t.id === partner.id)
                        );
                        // sort on name
                        this.partners.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;

                            return 0;
                        });

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
        formatDate(dateStr) {
            const [year, month, day] = dateStr.split("-");
            return `${day}-${month}-${year}`;
        },
    },
    computed: {
        transactionTypes() {
            const availableTypes = this.$store.state.available_types;
            return availableTypes.map((type) => {
                // Set text to the translated version if it's available, otherwise use the standard type
                let text = this.$t(type);
                if (text === type || !text) {
                    text = type;
                }

                return {
                    text: text, // Translates the type or uses the default if not available
                    value: type, // Keeps the original type value
                };
            });
        },

        getTitle() {
            return this.transactionTypes.find((type) => type.value === this.type).text;
        },
        start_date: {
            get() {
                return this.$store.getters.startDate;
            },
            set(value) {
                this.$store.commit("setStartDate", value);
            },
        },
        end_date: {
            get() {
                return this.$store.getters.endDate;
            },
            set(value) {
                this.$store.commit("setEndDate", value);
            },
        },
        formattedStartDate() {
            return this.formatDate(this.$store.getters.startDate);
        },
        formattedEndDate() {
            return this.formatDate(this.$store.getters.endDate);
        },
        date_range() {
            return `${this.formatDate(this.start_date)} - ${this.formatDate(this.end_date)}`;
        },
        getFilterCount() {
            let count = 0;

            if (this.$store.getters.startDate) count++;
            if (this.$store.getters.endDate) count++;
            if (this.partner_ids) count++;
            if (this.type) count++;

            return count.toString();
        },
        aggregateText() {
            const aggregateItem = this.aggregates.find((item) => item.value === this.aggregate);
            return aggregateItem ? aggregateItem.text : "";
        },
    },
    watch: {
        "$store.getters.productIds"() {
            this.getDailyTotals();
        },
        aggregate() {
            this.getDailyTotals();
        },
        partner_ids() {
            this.getDailyTotals();
        },
        start_date() {
            this.getDailyTotals();
        },
        end_date() {
            this.getDailyTotals();
        },
        allData(newVal) {
            if (newVal && newVal.length > 0) {
                this.filterByType();
            }
        },
        type() {
            this.filterByType();
        },
    },
};
</script>
<style lang=""></style>
