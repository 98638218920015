/**
 * The file enables `../plugins/i18n.js` to import all lang messages
 * in a one-shot manner. There should not be any reason to edit this file.
 */

import store from '../store'

const messages = store.getters.getTranslations

const languages = Object.keys(messages)

export {
  messages,
  languages
}

