import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import config from "../../config";
import nl from "vuetify/es5/locale/nl";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            nl,
            en,
        },
        current: "nl",
    },
    icons: {
        iconfont: "mdi",
    },
    iconPack: "mdi",
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: "#ffffff",
                accent: "black",
                secondary: "white",
                success: "white",
                info: "white",
                warning: "white",
                error: "white",
            },
            light: {
                primary: config.primary_color ? config.primary_color : "#3f51b5",
                accent: config.accent_color ? config.accent_color : "#3f51b5",
                secondary: config.secondary_color ? config.secondary_color : "#3f51b5",
                success: "#4CAF50",
                info: "#2196F3",
                warning: "#FB8C00",
                error: "#FF5252",
            },
        },
    },
});
