import { render, staticRenderFns } from "./Blank.vue?vue&type=template&id=217bfa46&"
import script from "./Blank.vue?vue&type=script&lang=js&"
export * from "./Blank.vue?vue&type=script&lang=js&"
import style0 from "./Blank.vue?vue&type=style&index=0&id=217bfa46&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports