<!-- vuetify 2 modal -->
<template>
    <v-dialog v-model="dialog" :scrollable="true" max-width="80vw">
        <template v-slot:activator="{ on }">
            <!-- <v-btn color="primary" dark>Open Dialog</v-btn> -->

            <v-btn primary small text v-on="on" :disabled="!products.length">
                Detailoverzicht
                <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Transacties details</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card rounded>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <v-menu
                                                v-model="menu_s"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        hide-details
                                                        v-model="formattedStartDate"
                                                        label="Start date"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="start_date" no-title scrollable>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col>
                                            <v-menu
                                                v-model="menu_e"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        hide-details
                                                        v-model="formattedEndDate"
                                                        label="End date"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="end_date" no-title scrollable> </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                multiple
                                                clearable
                                                v-model="localProductIds"
                                                :items="products"
                                                label="Producten"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                clearable
                                                v-model="provisioning_id"
                                                :items="provisionings"
                                                label="Voorzieningen"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                            ></v-select>
                                        </v-col>
                                        <v-col>
                                            <!-- budgets -->
                                            <v-select
                                                :disabled="!provisioning_id"
                                                clearable
                                                multiple
                                                v-model="localBudgetIds"
                                                :items="budgets"
                                                label="Budget"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col v-for="type in transactionTypes" :key="type" cols="12" sm="6" md="4">
                            <TransactionsSingle
                                :type="type"
                                :partners="partners"
                                :transactions="transactions"
                                :loading="loading"
                                @update="getTransactions"
                            ></TransactionsSingle>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Sluiten</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import TransactionsSingle from "../widgets/TransactionsSingle.vue";

export default {
    components: { TransactionsSingle },
    data: () => ({
        dialog: false,
        menu_s: false,
        menu_e: false,
        partners: [],
        debounceTimer: null,
        transactions: [],
        provisioning_id: null,
        localProductIds: [],
        localBudgetIds: [],
        loading: false,
    }),
    created() {
        this.getPartners();
        this.initializeLocalProductIds();
        this.initializeLocalBudgetIds();
    },
    methods: {
        initializeLocalProductIds() {
            this.localProductIds = [...this.$store.state.product_ids];
        },
        initializeLocalBudgetIds() {
            this.localBudgetIds = [...this.$store.state.budget_ids];
        },
        getTransactions() {
            this.loading = true;
            this.$http
                .get(
                    `${this.$config.backend_url}/dashboards/daily-totals?start_date=${
                        this.$store.getters.startDate
                    }&end_date=${this.$store.getters.endDate}&product_ids=${this.localProductIds.join(
                        ","
                    )}&budget_ids=${this.localBudgetIds.join(",")}&partner_ids=${
                        this.$store.getters.partnerIds
                    }&types=${this.$store.state.available_types}`
                )
                .then((response) => {
                    this.transactions = response.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        getPartners() {
            this.loading = true;
            var product_ids = this.localProductIds;

            for (let product_id of product_ids) {
                this.$http
                    .get(this.$config.backend_url + "/dashboards/product-info/" + product_id)
                    .then((response) => {
                        this.partners = this.partners.concat(response.data.partners);
                        this.partners = this.partners.filter(
                            (partner, index, self) => index === self.findIndex((t) => t.id === partner.id)
                        );
                        // sort on name
                        this.partners.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;

                            return 0;
                        });

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
        formatDate(dateStr) {
            const [year, month, day] = dateStr.split("-");
            return `${day}-${month}-${year}`;
        },
    },
    computed: {
        transactionTypes() {
            return this.$store.state.available_types;
        },
        start_date: {
            get() {
                return this.$store.state.start_date;
            },
            set(value) {
                this.$store.commit("setStartDate", value);
            },
        },
        end_date: {
            get() {
                return this.$store.state.end_date;
            },
            set(value) {
                this.$store.commit("setEndDate", value);
            },
        },
        formattedStartDate() {
            return this.formatDate(this.$store.getters.startDate);
        },
        formattedEndDate() {
            return this.formatDate(this.$store.getters.endDate);
        },
        products() {
            return this.$store.state.products;
        },
        provisionings() {
            return this.$store.state.provisionings;
        },
        budgets() {
            let provisioning = null;
            provisioning = this.provisionings.find((provisioning) => provisioning.id == this.provisioning_id);

            if (provisioning) {
                return provisioning.budgets;
            }

            return [];
        },
    },
    watch: {
        dialog(newVal) {
            if (newVal) {
                this.getTransactions();
            }
        },
        localProductIds(newVal, oldVal) {
            if (newVal !== oldVal && newVal.length > 0) {
                this.localBudgetIds = [];
                this.provisioning_id = null;
                this.loading = true;

                // Clear existing timer
                if (this.debounceTimer) {
                    clearTimeout(this.debounceTimer);
                }

                // Set new timer
                this.debounceTimer = setTimeout(() => {
                    this.getTransactions();
                }, 1000);
            }
        },
        localBudgetIds(newVal, oldVal) {
            if (newVal !== oldVal && newVal.length > 0) {
                this.localProductIds = [];
                this.getTransactions();
            }
        },
        start_date(newValue, oldValue) {
            // Check if a new date has been selected
            if (newValue !== oldValue) {
                this.menu_s = false;
                this.getTransactions();
            }
        },
        end_date(newValue, oldValue) {
            // Check if a new date has been selected
            if (newValue !== oldValue) {
                this.menu_e = false;
                this.getTransactions();
            }
        },
    },
};
</script>
<style lang=""></style>
