<template>
    <v-card style="height: 100%" rounded class="widget">
        <v-card-title flat>
            <v-card-title class="mb-0 py-0">Budget overzicht</v-card-title>
            <v-spacer></v-spacer>
            <v-menu offset-y :close-on-content-click="false" max-width="290px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon color="primary">mdi-filter-variant</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-text>
                        <!-- multiselect  -->
                        <v-row>
                            <v-col>
                                <v-select
                                    chips
                                    multiple
                                    v-model="product_ids"
                                    :items="products"
                                    label="Producten"
                                    item-text="name"
                                    item-value="id"
                                    hide-details
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-card-title>

        <v-card-text>
            <Pie v-if="!loading && !noData" :chart-options="chartOptions" :chart-data="chartData" />

            <div v-else-if="!loading && noData" class="pie-chart-no-data">
                <p>Geen data beschikbaar.</p>
            </div>

            <Loader :height="400" v-else />

            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Categorie</th>
                            <th class="text-right">Bedrag</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <div class="color-box" style="background: #ff6384"></div>
                                Totaal bedrag verkochte kaarten
                            </td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(totalAmountProduct) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-center">
                                <div class="color-box" style="background: #052e7f"></div>
                                Totaal bedrag betalingen
                            </td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(totalAmountPayment) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-center">
                                <div class="color-box" style="background: #36a2eb"></div>
                                Geldig bedrag op de kaarten
                            </td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(validAmount) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-center">
                                <div class="color-box" style="background: #ffce56"></div>
                                Verlopen bedrag op de kaarten
                            </td>
                            <td class="text-right">
                                <strong>{{ returnAsCurrency(expiredAmount) }}</strong>
                            </td>
                        </tr>
                    </tbody>
                    <v-divider vertical></v-divider>
                    <thead class="mt-5">
                        <tr>
                            <th class="text-left">Categorie</th>
                            <th class="text-right">Aantal</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Totaal aantal verkochte kaarten</td>
                            <td class="text-right">
                                <strong>{{ validCount }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Totaal aantal kaarten met een betaling</td>
                            <td class="text-right">
                                <strong>{{ countPayment }}</strong>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels);

export default {
    components: {
        Pie,
    },
    data() {
        return {
            noData: false,
            loading: true,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                        color: "white",
                        align: "middle",
                        formatter: function (val) {
                            let parsed = val.toFixed(2).replace(".", ",");
                            parsed = parsed.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

                            return `€ ${parsed}`;
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed) {
                                    label += `€ ${context.parsed.toFixed(2).replace(".", ",")}`;
                                }
                                return label;
                            },
                        },
                    },
                },
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "Budget",
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#052E7F"],
                        hoverBackgroundColor: [
                            // darker version
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56",
                            "#052E7F",
                        ],
                    },
                ],
            },
            totalAmountPayment: 0,
            countPayment: 0,
            validAmount: 0,
            validCount: 0,
            expiredAmount: 0,
            expiredCount: 0,
            totalAmountProduct: 0,
            countProduct: 0,
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            var product_ids = this.$store.getters.productIds;

            // if this.$store.getters.productIds is empty, then we need to get all the product ids
            if (this.product_ids.length == 0) {
                this.products.forEach((product) => {
                    product_ids.push(product.id);
                });
            } else if (this.$store.getters.productIds.length > 0) {
                product_ids = this.$store.getters.productIds;
            }

            if (product_ids.length == 0) {
                this.loading = true;

                await this.getProductIds();

                this.loading = false;
            }

            if (product_ids.length > 0) {
                this.$http
                    .post(`${this.$config.backend_url}/dashboards/product-info-summary`, {
                        product_ids: product_ids,
                    })
                    .then((response) => {
                        const data = response.data;
                        if (
                            data.totalAmountProduct === 0 &&
                            data.validAmount === 0 &&
                            data.expiredAmount === 0 &&
                            data.totalAmountPayment === 0
                        ) {
                            this.noData = true;
                        } else {
                            this.noData = false;
                        }

                        this.chartData.labels = [
                            "Totaal bedrag verkochte kaarten",
                            "Geldig bedrag op de kaarten",
                            "Verlopen bedrag",
                            "Totaal bedrag betalingen",
                        ];
                        this.chartData.datasets[0].data = [
                            data.totalAmountProduct,
                            data.validAmount,
                            data.expiredAmount,
                            data.totalAmountPayment,
                        ];

                        // currency
                        this.validAmount = data.validAmount;
                        this.totalAmountProduct = data.totalAmountProduct;
                        this.countPayment = data.countPayment;
                        this.expiredAmount = data.expiredAmount;
                        this.totalAmountPayment = data.totalAmountPayment;

                        // counts
                        this.expiredCount = data.expiredCount;
                        this.countProduct = data.countProduct;

                        // validcount fixen

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                console.warn("Geen product_ids gevonden om naar het endpoint te sturen");
                this.loading = false;
            }
        },
        async getProductIds() {
            await this.$http
                .get(this.$config.backend_url + "/dashboards/programs?data=products")
                .then((response) => {
                    // sort on name
                    response.data["products"].sort(function (a, b) {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });

                    let products = response.data["products"];
                    let product_ids = [];
                    products.forEach((product) => {
                        product_ids.push(product.id);
                    });

                    this.$store.commit("setProductIds", product_ids);
                })
                .catch((error) => {
                    this.loading = false;
                    this.error_response = "not_authorized";
                    if (error.response.data.error && error.response.data.error == "not_verified") {
                        this.error_response = "not_verified";
                    }
                });
        },
        returnAsCurrency(val) {
            if (!val) return "€ 0,00";
            let parsed = val.toFixed(2).replace(".", ",");
            parsed = parsed.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

            return `€ ${parsed}`;
        },
    },
    computed: {
        product_ids: {
            get() {
                return this.$store.getters.productIds;
            },
            set(value) {
                this.$store.commit("setProductIds", value);
            },
        },
        products: {
            get() {
                return this.$store.getters.products;
            },
            set(value) {
                this.$store.commit("setProducts", value);
            },
        },
        getFilterCount() {
            return this.$store.getters.productIds.length;
        },
    },
    watch: {
        product_ids: {
            handler() {
                this.getData();
            },
            immediate: true,
        },
    },
};
</script>

<style>
.color-box {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #929292;
    border-radius: 3px;
}

.pie-chart-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 100%;
    font-size: 20px;
    color: #929292;
}
</style>
