var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Transition',{attrs:{"name":"fade"}},[(
_vm.$route.name == 'login' ||
_vm.$route.name == 'password-reset' ||
_vm.$route.name == 'change-password' ||
_vm.$route.name == 'forgot-username' ||
_vm.$route.name == 'offline'
    )?_c('blank'):_c('full')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }