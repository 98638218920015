<template>
    <v-main>
        <v-row>
            <v-col cols="12" md="6" lg="4">
                <BudgetOverview />
            </v-col>
            <v-col cols="12" md="6" lg="8">
                <v-row>
                    <v-col cols="12">
                        <Transactions />
                    </v-col>
                    <v-col cols="12" md="6">
                        <Partners></Partners>
                    </v-col>

                    <v-col cols="12" md="6">
                        <ProvisioningBudget />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
// import Widget from "../components/Widget.vue";

import BudgetOverview from "../components/widgets/BudgetOverview.vue";
// import ActivationCount from "../components/widgets/ActivationCount.vue";
import Partners from "../components/widgets/Partners.vue";
import Transactions from "../components/widgets/Transactions.vue";
import ProvisioningBudget from "../components/widgets/ProvisioningBudget.vue";

export default {
    components: {
        // Widget,
        BudgetOverview,
        // ActivationCount,
        Partners,
        Transactions,
        ProvisioningBudget,
    },
    name: "Dashboard",
    data() {
        return {
            widgets: [],
            partners: [],
            products: [],
            provisionings: [],
            menu_s: false,
            menu_e: false,
        };
    },
    created() {
        this.getProgramsData("partners");
        this.getProgramsData("products");
        this.getProgramsData("provisionings");
        this.getProgramsData("available_types");
    },
    methods: {
        getProgramsData(type) {
            this.$http
                .get(this.$config.backend_url + "/dashboards/programs?data=" + type)
                .then((response) => {
                    if (type === "provisionings") {
                        this.sortOnName(response.data, type);

                        this.$store.commit("setProvisionings", response.data[type]);
                    }

                    if (type === "products") {
                        this.sortOnName(response.data, type);

                        this.$store.commit("setProducts", response.data[type]);
                    }

                    if (type === "partners") {
                        this.sortOnName(response.data, type);

                        this.$store.commit("setPartners", response.data[type]);
                    }

                    if (type === "available_types") {
                        this.$store.commit("setAvailableTypes", response.data[type]);
                    }

                    this[type] = response.data[type];
                })
                .catch((error) => {
                    this.loading = false;
                    this.error_response = "not_authorized";
                    if (error.response.data.error && error.response.data.error == "not_verified") {
                        this.error_response = "not_verified";
                    }
                });
        },
        sortOnName(data, type) {
            data[type].sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
        },
    },
    computed: {
        start_date: {
            get() {
                return this.$store.state.start_date;
            },
            set(value) {
                this.$store.commit("setStartDate", value);
            },
        },
        end_date: {
            get() {
                return this.$store.state.end_date;
            },
            set(value) {
                this.$store.commit("setEndDate", value);
            },
        },
        partner_ids: {
            get() {
                return this.$store.state.partner_ids;
            },
            set(value) {
                this.$store.commit("setPartnerIds", value);
            },
        },
        budget_ids: {
            get() {
                return this.$store.state.budget_ids;
            },
            set(value) {
                this.$store.commit("setBudgetIds", value);
            },
        },
        product_id: {
            get() {
                return this.$store.state.product_id;
            },
            set(value) {
                this.$store.commit("setProductId", value);
            },
        },
        provisioning_ids: {
            get() {
                return this.$store.state.provisioning_ids;
            },
            set(value) {
                this.$store.commit("setProvisioningIds", value);
            },
        },
    },
};
</script>
<style>
.main-dashboard {
    width: 100%;
    min-height: 75vh;
}
</style>
