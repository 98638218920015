<template>
    <v-card :loading="loading" rounded class="widget">
        <v-card-title flat>
            <v-card-title class="mb-0 py-0">Aangesloten partners</v-card-title>
            <v-spacer></v-spacer>
            <v-menu offset-y :close-on-content-click="false" max-width="290px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon color="primary">mdi-filter-variant</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-text>
                        <!-- multiselect  -->
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    hide-details
                                    v-model="product_id"
                                    :items="combinedProducts"
                                    item-text="name"
                                    item-value="id"
                                    label="Selecteer producten"
                                    dense
                                    outlined
                                    clearable
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :disabled="product_id ? false : true"
                                    @change="setBudgets"
                                    hide-details
                                    v-model="provisioning_id"
                                    :items="provisionings"
                                    item-text="name"
                                    item-value="id"
                                    label="Selecteer voorziening"
                                    dense
                                    outlined
                                    clearable
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :disabled="!provisioning_id"
                                    hide-details
                                    v-model="budget_id"
                                    :items="budgets"
                                    item-text="name"
                                    item-value="id"
                                    label="Selecteer budget"
                                    dense
                                    outlined
                                    clearable
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-card-title>
        <v-card-text>
            <div class="text-center mb-3">
                <strong> {{ selectedProductName }} </strong>
            </div>
            <v-data-table
                :items-per-page="7"
                :footer-props="{ 'items-per-page-options': [7] }"
                :headers="headers"
                :items="partners"
                sort-desc
                sort-by="name"
                dense
                class="adjusted-margin"
                calculate-widths
            >
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import "charts.css";

export default {
    data() {
        return {
            selected_provisioning_ids: [],
            headers: [
                {
                    text: "Partner",
                    align: "start",
                    sortable: true,
                    value: "name",
                },
            ],
            provisionings: [],
            budget_ids: [],
            loading: false,
            selected_partner_ids: [],
            budgets: [],
            partners: [],
            product_id: -1,
        };
    },
    created() {
        this.provisionings = this.$store.state.provisionings;

        // Sort provisionings
        this.provisionings.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        this.getPartnersFromBudgetId();
    },
    methods: {
        setBudgets(event) {
            this.budgets = this.provisionings.find((provisioning) => provisioning.id === event).budgets;
        },
        getPartnersFromBudgetId() {
            if (!this.budget_id) return;
            this.loading = true;
            this.$http
                .get(this.$config.backend_url + "/dashboards/budgets/" + this.budget_id)
                .then((response) => {
                    this.partners = response.data.partners;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        getPartnersFromProduct() {
            if (!this.product_id) return;

            if (this.product_id === -1) {
                this.partners = this.$store.getters.partners;
                return;
            }

            this.loading = true;
            this.$http
                .get(this.$config.backend_url + "/dashboards/product-info/" + this.product_id)
                .then((response) => {
                    this.partners = response.data.partners;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        budget_id: {
            get: function () {
                return this.$store.state.budget_id;
            },
            set: function (newVal) {
                this.$store.commit("setBudgetId", newVal);
            },
        },
        provisioning_id: {
            get: function () {
                return this.$store.state.provisioning_id;
            },
            set: function (newVal) {
                this.$store.commit("setProvisioningId", newVal);
            },
        },
        getFilterCount() {
            let count = 0;
            if (this.budget_id) count++;
            if (this.provisioning_id) count++;
            if (this.product_id) count++;
            return count.toString();
        },
        combinedProducts() {
            // Add 'Alle' as a product with an id
            const allItem = { name: "Alle", id: -1 };

            // Concatenate 'Alle' with the products from the store
            return [allItem, ...this.$store.getters.products];
        },
        selectedProductName() {
            const selectedProduct = this.combinedProducts.find((product) => product.id === this.product_id);
            return selectedProduct ? selectedProduct.name : "";
        },
    },
    watch: {
        provisioning_id: function () {
            if (this.provisioning_id) {
                this.product_id = null;
            }
        },
        budget_id: function () {
            if (this.budget_id) {
                this.product_id = null;
            }
            this.getPartnersFromBudgetId();
        },
        product_id: function () {
            if (this.product_id) {
                this.budget_id = null;
                this.provisioning_id = null;
                this.getPartnersFromProduct();
            }
        },
        "$store.getters.partners": {
            immediate: true,
            handler(newValue) {
                this.partners = newValue;
            },
        },
    },
};
</script>

<style>
.selected-product-name {
    font-size: 0.5em;
    color: gray;
}
</style>
