<template>
    <v-card rounded class="widget">
        <v-card-title flat>
            <v-card-title class="mb-0 py-0"> {{ getTitle }} (&euro;) </v-card-title>
            <v-spacer></v-spacer>
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon> <v-icon>mdi-filter</v-icon> </v-btn>
                </template>

                <v-card style="max-width: 300px">
                    <v-card-text>
                        <!-- multiselect  -->
                        <v-row>
                            <v-col>
                                <v-select
                                    hide-details
                                    v-model="partner_ids"
                                    :items="partners"
                                    item-text="name"
                                    item-value="id"
                                    label="Selecteer partners"
                                    multiple
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    hide-details
                                    v-model="aggregate"
                                    :items="aggregates"
                                    item-text="text"
                                    item-value="value"
                                    label="Selecteer aggregatie"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-card-title>

        <v-card-text>
            <bar-chart v-if="!loading" style="height: 250px" :chart-options="chartOptions" :chart-data="chartData" />
            <Loader v-else />
        </v-card-text>
    </v-card>
</template>
<script>
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        partners: {
            type: Array,
            required: true,
        },
        transactions: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        BarChart: Bar,
    },
    data() {
        return {
            menu_e: false,
            menu_s: false,
            // day, month or year
            aggregate: "month",
            aggregates: [
                { text: "Dag", value: "day" },
                { text: "Maand", value: "month" },
                { text: "Jaar", value: "year" },
            ],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                // hide legend
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";

                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat("nl-NL", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(context.parsed.y);
                                }
                                return label;
                            },
                        },
                    },
                },
            },
            chartData: {
                type: "bar",
                labels: [],
                datasets: [
                    {
                        label: "Totalen",
                        data: [],
                        backgroundColor: ["#FF6384"],
                        hoverBackgroundColor: ["#FF6384"],
                    },
                ],
            },
            debounce: null,
        };
    },
    methods: {
        getDailyTotals() {
            // filter transactions by type
            let transactions = this.transactions.filter((transaction) => transaction.type === this.type);

            // if aggregate is day, we get an array of objects
            if (this.aggregate === "day") {
                let rows = transactions;
                this.chartData.labels = rows.map((row) => row.day);
                this.chartData.datasets[0].data = rows.map((row) => row.amount);
            } else {
                // if aggregate is month or year, we get an object
                let rows = this.transformData(transactions);
                this.chartData.labels = rows.map((row) => row.name);
                this.chartData.datasets[0].data = rows.map((row) => row.amount);
            }
        },
        transformData(data) {
            // {
            // "day": "2023-08-10",
            //     "amount": 0,
            //         "count": 0,
            //             "type": "PAYMENT"
            // }
            if (this.aggregate === "day") {
                return data.map((item) => {
                    return {
                        name: item.day,
                        amount: item.amount,
                    };
                });
            } else if (this.aggregate === "month") {
                // group on month. Day gives date to group on
                let months = [];
                data.forEach((item) => {
                    let month = item.day.substring(0, 7);
                    let index = months.findIndex((month) => month.name === item.day.substring(0, 7));
                    if (index === -1) {
                        months.push({
                            name: month,
                            amount: item.amount,
                        });
                    } else {
                        months[index].amount += item.amount;
                    }
                });

                return months;
            } else if (this.aggregate === "year") {
                // group on year. Day gives date to group on
                let years = [];
                data.forEach((item) => {
                    let year = item.day.substring(0, 4);
                    let index = years.findIndex((year) => year.name === item.day.substring(0, 4));
                    if (index === -1) {
                        years.push({
                            name: year,
                            amount: item.amount,
                        });
                    } else {
                        years[index].amount += item.amount;
                    }
                });

                return years;
            }
        },
    },
    computed: {
        transactionTypes() {
            const availableTypes = this.$store.state.available_types;

            return availableTypes.map((type) => {
                return {
                    text: this.$t(type), // Translates the type
                    value: type, // Keeps the original type value
                };
            });
        },
        getTitle() {
            return this.transactionTypes.find((type) => type.value === this.type).text;
        },
        partner_ids: {
            get() {
                return this.$store.getters.partner_ids;
            },
            set(value) {
                this.$store.commit("setPartnerIds", value);
                this.$emit("update");
            },
        },
    },
    watch: {
        transactions() {
            this.getDailyTotals();
        },
        aggregate() {
            this.getDailyTotals();
        },
    },
};
</script>
<style lang=""></style>
