<template>
  <div>
    <v-progress-linear
      v-if="!strength_meter"
      :color="score.color"
      :value="score.value"
      class="password-progress"
      rounded
      height="10"
    ></v-progress-linear>
    <v-text-field
      :outlined="outlined"
      :label="label"
      v-model="password"
      :rules="[rules.required, rules.strength]"
      :type="show_password ? 'text' : 'password'"
      :append-icon="!show_password ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show_password = !show_password"
    ></v-text-field>
  </div>
</template>

<script>
import zxcvbn from "zxcvbn";

export default {
  props: ["value", "outlined", "label", "strength_meter"],
  data() {
    return {
      show_password: false,
      password: "",
      rules: {
        required: (value) => !!value || this.$t("mandatory"),
        strength: (v) =>
          /* eslint-disable */
          (v &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\]\[/.\\\-_=~`€{}|;:(),'"<>?\^+!@#\$%\^&\*])(?=.{8,})/.test(
              v
            )) ||
          this.$t("passwordRules"),
        /* eslint-ensable */
      },
    };
  },
  created() {
    this.password = this.value;
  },
  computed: {
    score() {
      const result = zxcvbn(this.password);

      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100,
          };
        case 3:
          return {
            color: "light-green",
            value: 75,
          };
        case 2:
          return {
            color: "yellow",
            value: 50,
          };
        case 1:
          return {
            color: "orange",
            value: 25,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.password = this.value;
      },
    },
    password: {
      handler() {
        this.$emit("input", this.password);
      },
    },
  },
};
</script>

<style >
.password-progress {
  position: relative;
  top: 37px;
  width: 50px;
  margin-left: auto;
  margin-right: 30px;
}
</style>